import { MenuEntry } from 'orbui'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://orbeswap.com/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://orbeswap.com/farms',
  },
  /* {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Overview',
        href: '#',
      },
      {
        label: 'Tokens',
        href: '#',
      },
      {
        label: 'Pairs',
        href: '#',
      },
      {
        label: 'Accounts',
        href: '#',
      },
    ],
  },
  
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Voting',
        href: '#',
      },
      {
        label: 'Github',
        href: '#',
      },
      {
        label: 'Docs',
        href: '#',
      },
      {
        label: 'Blog',
        href: '#',
      },
    ],
  }, */
]

export default config
