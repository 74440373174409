import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #000000;
    overflow-x: hidden;


    img {
      height: auto;
      max-width: 100%;
    }
  }
  .orb-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Assurez-vous que le fond est en arrière-plan */
}
`

export default GlobalStyle
